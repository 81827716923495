import dynamic from 'next/dynamic';
import { useStore } from 'react-redux';

import { useMobile } from '../../hooks/useMobile';
import Catalog from '../Catalog';

import MainBanner from './MainBanner';

const Items = dynamic(() => import('../Items'));
const Counts = dynamic(() => import('../Counters'));
const Sidebar = dynamic(() => import('../Sidebar'));
const MainMobileBottomRow = dynamic(() => import('./MainMobileBottomRow'));
const MainDesktopBottomRow = dynamic(() => import('./MainDesktopBottomRow'));

const Main = () => {
  const isMobile = useMobile();

  const {
    data: { providerList, brandList },
    beriBarData: { banners },
  } = useStore().getState();

  return isMobile ? (
    <div>
      <div className="main__inner">
        <div className="wrapper wrapper--40">
          <div className="main__counts">
            <Counts />
          </div>
          {/* <h2 className="catalog__title">Выгодное предложение</h2> */}
          <Catalog main />
          <Items items={brandList} type="brand" title="Бренды" />
          <Items items={providerList} type="supplier" title="Поставщики" />
          <MainMobileBottomRow />
        </div>
      </div>
    </div>
  ) : (
    <main className="main main--pt50">
      <div className="wrapper wrapper--1190">
        <div className="main__inner">
          <div className="main__row main__row--margin">
            <div className="main__side">
              <Sidebar />
            </div>
            <div className="main__catalog">
              <div className="main__catalog-wrapper">
                {!!banners.length && (
                  <div className="main__banner">
                    <MainBanner />
                  </div>
                )}
                {/* <h2 className="catalog__title">Выгодное предложение</h2> */}
                <Catalog main />
              </div>
            </div>
          </div>
          <MainDesktopBottomRow />
        </div>
      </div>
    </main>
  );
};

export default Main;
