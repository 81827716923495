import dynamic from 'next/dynamic';
import { useStore } from 'react-redux';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

const ImgWithFallback = dynamic(() => import('../ImgWithFallback'));

SwiperCore.use([Pagination, Autoplay]);

const MainBanner = () => {
  const {
    beriBarData: { banners },
  } = useStore().getState();

  const isBannerEnabled = banners.length > 1;

  return !!banners.length ? (
    <div className="banner">
      <Swiper
        pagination={true}
        modules={[Pagination]}
        tag="div"
        wrapperTag="ul"
        spaceBetween={0}
        slidesPerView={1}
        loop={isBannerEnabled}
        autoplay={isBannerEnabled}
        className="banner__carousel"
        enabled={isBannerEnabled}
      >
        {banners.map((item, id) => {
          return (
            <SwiperSlide key={id} className="banner__slide" tag="li">
              <ImgWithFallback photoSrc={item.resize_banner || item.banner} photoWebp={item.webp_banner} alt=""/>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : null;
};

export default MainBanner;
